div.card-icon-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;

  .card-icon-text{
    width: 100%;
    justify-content: center;
  }

  .card-icon{
    align-items: center;
    justify-content: center;

    svg{
      width: 99px;
      height: 99px;
    }

    &-disabled{
      path{
        fill: var(--neutral-gray-03);
      }
    }
  }
}
