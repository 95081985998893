$brown-light: var(--tertiary-brown-900);
$white-color: var(--white);
$neutral-dark: var(--neutral-dark-05);
div.alert-card{
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  background-color: $white-color;
  border: 1px solid $brown-light;
  border-radius: 4px;

  &__icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px;
    svg{
      width: 36px;
      height: 36px;
    }
    path{
      fill: $brown-light;
    }
    span{
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: $brown-light;
    }
  }

  &__text{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $neutral-dark;
  }
}
