$gray05: var(--neutral-gray-05);
.menu-account{
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;

    @media(max-width: 902px){
        flex-wrap: wrap;
    }

    @media(max-width: 576px){
      gap: 0;
  }

    &__left, &__right{
        width: 100%;
    }

    &__left{
        max-width: 400px;
        .card{
            flex-direction: column;
        }
        @media(max-width: 576px){
            padding: 8px 16px;
            max-width: 100%;
        }
        @media(max-width: 902px){
            max-width: 608px;
        }
    }

    &__right{
        max-width: 608px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .card{
          @media(max-width: 576px){
                margin: 8px auto 0;
                max-width: calc(100% - 32px);
                padding-left: 16px!important;
                padding-right: 16px!important;
            }
        }

        @media(max-width: 1071px){
            max-width: auto;
        }
    }

    &__right-btn-spacing {
        padding-bottom: 90px;
    }

    &__avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background-color: var(--primary-purple-900);
        color: #fff;
        width: 103px;
        height: 103px;
        font-size: 93px;
        font-weight: 700;
    }

    &__infos-user{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 26px;
        border-bottom: 1px solid;
        border-color: $gray05;
    }

    &__infos-sociais{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        width: 100%;

        &-list{
            display: flex;
            flex-direction: column;
            width: 100%;
            span{
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: $gray05;
            }
            svg{
              width: 47px;
              height: 47px;
            }
        }

        &-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            &-text{
                display: block;
                min-width: 68px;
            }
        }
    }

    &__webview-info{
        display: flex;
        font-size: 14px;
        path {fill: #550087;}
    }

    .disable-account{
        &__actions{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            button{
                width: 308px;
                max-width: 100%;
            }
        }
    }
}
.account-disabled{
    border: none;

    .menu-account__avatar{
        background-color: var(--neutral-gray-01);
    }
}
.icon-face-sad{
    display: block;
    position: relative;

  svg{
    width: 21px;
    height: 21px;
    transform: translate(13px, 3px);
  }
}

.link {
    cursor: pointer;
    text-decoration: underline;
    color: #550087;
}

.icon {
    margin: -3px 8px;
}

@media(max-width: 576px){
  .hidden-mobile-panel {
    display: none;
  }
}

@media(min-width: 576px){
    .hidden-desktop-panel {
      display: none;
    }
  }

.disable-account-icon {
    width: 50px !important;
    height: 50px !important;
}
