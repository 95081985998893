.success-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  svg{
    width: 76px;
    height: 76px;
  }
  color: var(--neutral-dark-05);
  text-align: center;
}
