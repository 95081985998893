div.card{
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--white);
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;

  &-full{
    width: 100%;
  }

  &-disabled{
    background: var(--neutral-gray-03);
  }
}
.card-sticky{
  position: sticky!important;
}
