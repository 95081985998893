.breakline-desktop{
    @media(max-width: 576px){
      display: none;
    }
  }
  
  .breakline-mobile{
    @media(min-width: 576px){
      display: none;
    }
  }