@for $i from 1 through 42 {
  .m-#{$i} {
    margin: $i + px;
  }
  .mt-#{$i}{
    margin-top: $i + px;
  }
  .mb-#{$i}{
    margin-bottom: $i + px;
  }
  .ml-#{$i}{
    margin-left: $i + px;
  }
  .mr-#{$i}{
    margin-right: $i + px;
  }
}

@for $i from 1 through 42 {
  .p-#{$i} {
    padding: $i + px;
  }
  .pt-#{$i}{
    padding-top: $i + px;
  }
  .pb-#{$i}{
    padding-bottom: $i + px;
  }
  .pl-#{$i}{
    padding-left: $i + px;
  }
  .pr-#{$i}{
    padding-right: $i + px;
  }
}

@for $i from 0 through 42 {
  .mi-#{$i} {
    margin: $i + px!important;
  }
  .mti-#{$i}{
    margin-top: $i + px!important;
  }
  .mbi-#{$i}{
    margin-bottom: $i + px!important;
  }
  .mli-#{$i}{
    margin-left: $i + px!important;
  }
  .mri-#{$i}{
    margin-right: $i + px!important;
  }
}

@mixin configFont($size, $lineHeight, $weight){
  font-size: $size + px;
  line-height: $lineHeight + px;
  font-weight: $weight;
}
