.loading-full-screen{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #00000047;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  svg{
    width: 36px;
    height: 36px;
  }
}
