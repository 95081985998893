.social-name {
    .check-box {
        padding-bottom: 4px;
    }
}

.signup-header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .logo-text {
        margin-bottom: 0;
    }
}