.logo-text{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  svg {
    width: 36px;
    height: 36px;
  }

  span{
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

}
