.update-user-data-menu{
    display: flex;
    flex-direction: column;
    width: 100%;

    &__link{
      display: flex;
      padding: 16px 18px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--neutral-dark-05);
      border-bottom: 1px solid;
      border-color: var(--neutral-gray-01);

      &:hover{
        background-color: rgba(0, 0, 0, 0.032)
      }

      &.link-selected{
        background-color: var(--neutral-gray-03);
        color: var(--primary-purple-900);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        border-color: var(--primary-purple-900);
        border-bottom: 2px solid;
      }

      svg{
        margin-left: auto;
      }
    }
  }
  .account-data-info-icon{
    path{
      fill: var(--primary-purple-900);
    }
  }

  :root {
    --break-text-with: 350px;
    --break-text-mobile-with: 350px;
  }
  .brake-info-text{
    @media(min-width: 577px){
      max-width: var(--break-text-with);
    }
    @media(max-width: 576px){
      max-width: var(--break-text-mobile-with);
    }
  }
  .menu-account {
    .half-field{
      width: calc(50% - 5px);

      @media(max-width: 576px){
        width: 100%;
      }
    }

    .fields-gender {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr;

      input{
        width: 100%;
      }
      @media(max-width: 576px){
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    .field-cep{
      max-width: 128px;
      input{
        width: 100%;
      }
    }

    .address__us{
      gap: 8px;
    }

    .triple-fields{
      display: grid;
      grid-template-columns: calc(50% - 4px) 27% 23%;
      gap: 8px;
      align-items: center;
      @media(max-width: 576px){
        grid-template-columns: 1fr;
        gap: 0;
        padding-bottom: 16px;
      }
    }

    .field-number-address {
      input{
        width: 100%;
      }
    }

    .browse-icon {
      margin-right: 35px;
      width: 48px;
      height: 48px;

      svg{
        width: 48px;
        height: 48px;
      }
    }

    .browser {
      font-size: 18px;
      text-transform: capitalize;
    }

    .info {
      font-size: 12px;
      width: 270px;
    }

    .session-action {
      margin-left: 130px;

      .current-session {
        margin-left: -40px;
        color: #00371E;
      }
    }
  }

  .hidden-panel{
    @media(max-width: 576px){
      display: none;
    }
  }
  .btn-update-data-mobile{
    bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    flex-grow: 1;
    align-self: end;
    margin-top: 16px;
    div{
      background-color: #fff;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button{
      margin: 0!important;
      width: 296px;
    }
  }
  .height-full{
    height: 100%;
  }

  .caution {
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
  }
