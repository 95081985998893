:root{
  --primary-purple-700: #8539B6;
  --primary-purple-300: #E18DFF;
  --primary-purple-900: #550087;
  --primary-purple-800: #8200C8;

  --secondary-green-300: #2CB477;
  --secondary-green-50: #8EFFC9;
  --secondary-green-800: #005B28;
  --secondary-green-900: #00371E;

  --error-red: #E3332A;

  --tertiary-red-400: #EF4846;
  --tertiary-pink-50: #FFBDAD;
  --tertiary-brown-800: #A40012;
  --tertiary-brown-900: #A32929;

  --neutral-gray-01: #D2D2D2;
  --neutral-gray-02: #BBB;
  --neutral-gray-03: #DDD9E0;
  --neutral-gray-04: #7A7A7A;
  --neutral-gray-06: #BAB8B8;

  --neutral-dark-05: #373737;
  --neutral-dark-01: #282828;

  --white: #FFFFFF;
  --hover: #f0f0f0;

  --aux-pink-01: #FCDADA;
  --aux-green-02: #7D7300;
}
