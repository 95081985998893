.terms-policy-container {
  margin: 25px auto 0;
  max-width: 554px;
  padding: 32px 0 !important;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);
  border-radius: 4px;

  .title-container {
    padding: 0 40px;
  }

  &-title {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--neutral-gray-01);
    padding-bottom: 18px;

    h1 {
      text-align: left !important;
    }
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &-description {
    max-height: 68vh;
    overflow-y: auto;
    padding: 0 30px;
    margin: 0 10px;

    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: rgba(217, 217,217,0.6);
      border-radius: 6px;
    }
     
    &::-webkit-scrollbar-thumb {
      background: #c0bfbf; 
      border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8; 
    }

    p {
      text-align: left !important;
    }
  }
}