.check-box{
  display: flex;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .check-box-checked{
    position: absolute;
    top: 0px;
    left: 0;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 2px;
    border: 1.5px solid var(--neutral-dark-05);
  }

  &:hover input ~ .check-box-checked {
    background-color: var(--hover);
  }

  input:checked ~ .check-box-checked {
    background-color: var(--primary-purple-900);
  }

  .check-box-checked:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .check-box-checked:after {
    display: block;
  }

  .check-box-checked:after {
    left: 4.55px;
    top: 0px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
