$darken-green: var(--secondary-green-900);
$brown-light: var(--tertiary-brown-900);
$white-color: var(--white);
$neutral-dark: var(--neutral-dark-05);

div.alert-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 8px 16px;
  position: fixed;
  max-width: 400px;
  width: 100%;
  min-width: 340px;
  top: 21px;
  left: 50%;
  transform: translateX(-50%);

  @media(max-width: 576px){
    top: 62px;

    &-webview {
      top: 6px;
    }
  }


  &-error{
    background-color: $brown-light;
  }

  &-success{
    background-color: $darken-green;
  }

  &-neutral{
    background-color: $neutral-dark;
  }

  &__icon-left{
    svg{
      width: 32px;
      height: 32px;
    }

    path{
      fill: $white-color;
    }
  }

  &__icon-right{
    cursor: pointer;
    svg{
      width: 24px;
      height: 24px;
    }
    path{
      fill: $white-color;
    }
  }

  &__text{
    flex-grow: 1;
    color: $white-color;
    padding: 0 13px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
}
