.nav-bar{
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    height: 56px;
    max-height: 56px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

    @media(max-width: 902px){
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 12px;
    }

    &__back{
        svg{
          display: none;
        }
        @media(max-width: 902px){
            svg{
                display: block;
            }
        }
    }

    &__logo{
        margin-left: 104px;
        cursor: pointer;
        @media(max-width: 902px){
            margin-left: inherit;
            justify-self: center;
        }
    }

    &__menu{
        margin-left: auto;
        margin-right: 16px;
        button{
            white-space: nowrap;
        }
        @media(max-width: 902px){
            margin-left: inherit;
            justify-self: end;
            margin-right: 8px;
        }
    }

    .logo-text{
        margin-bottom: 0;
        span{
            white-space: nowrap;
        }
    }

    &-btn-icon{
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
}
