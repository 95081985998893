$neutralDark: var(--neutral-dark-05);
$purpleDark: var(--primary-purple-900);

div.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);

  @media(max-width: 902px ){
    &-swipe{
      display: block;
      margin: 0 auto 8px auto;
      width: 59px;
      height: 4px;
      background-color: #7A7A7A;
    }
  }

  &-card {
    max-width: 580px;
    background-color: #fff;
    position: absolute;
    padding: 32px 40px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    @media(min-width: 921px) {
      min-width: 380px;
    }

    @media(min-width: 1500px) {
      max-width: 780px;
    }

    @media(min-width: 903px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media(max-width: 902px){
      width: 100%;
      max-width: 100%;
      bottom: 0;
      left: 0;
      padding: 24px 32px;
    }



  }

  &-title h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $neutralDark;
    margin: 0 0 16px;

    @media(max-width: 902px){
      font-size: 18px;
      line-height: 28px;
    }
  }

  &-description p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $neutralDark;
    margin: 0 0 24px;

    @media(max-width: 902px){
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-icon {
    text-align: center;

    svg {
      width: 87px;
      height: 87px;
    }

    path {
      fill: $purpleDark;
    }

    @media(max-width: 902px){
     display: none;
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

}

.icon-red > svg > path {
    fill: var(--tertiary-brown-900);
}
