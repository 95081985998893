.button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    padding: 12px;
    width: auto;
    height: 44px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;

    @media(max-width: 576px){
        margin-bottom: 32px;
    }

    &.btn-full{
      width: 100%;
    }

    &.btn-purple{
        background: var(--primary-purple-900);
        color: var(--white);
    }

    &.btn-outline{
        border-width: 2px;
        border-style: solid;
        border-color: var(--primary-purple-900);
        background: var(--white);
        color: var(--primary-purple-900);
    }

    &.btn-white{
        background: var(--white);
        color: var(--primary-purple-900);
    }

    &.btn-red{
        color: var(--white);
        background: var(--tertiary-brown-900);
    }

    &:disabled {
        border: none;
        cursor: default;
        background: var(--neutral-gray-04);
        color: #D2D2D2;
    }

    &:hover{
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
    }

}
