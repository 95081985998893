@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&display=swap');
@import 'functions';
@import 'variables';
@import 'checkbox';

#root, html, body{
    width: 100%;
    height: 100%;
}
body {
    margin: 0;
    font-family: 'Urbanist', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--white);

    * {
      font-family: 'Urbanist', sans-serif;
    }
}

body.webview {
  .grecaptcha-badge { 
    visibility: hidden !important;
  }
}

h1,h2,h3,h4,h5{
  margin: 0;
}

h1{
  @include configFont(32, 40, 700);
}
h2{
  @include configFont(24, 32, 700);
}
h3{
  @include configFont(20, 30, 700);
}
h4 {
  @include configFont(18, 28, 700);
}
h5{
  @include configFont(16, 24, 600);
}
h6{
  @include configFont(14, 16, 600);
}

span, p, a{
  &.sm{
    @include configFont(12, 14, 400);
  }
  &.sm-bold{
    @include configFont(12, 14, 700);
  }
  &.md{
    @include configFont(14, 18, 400);
  }
  &.md-bold{
    @include configFont(14, 18, 500);
  }
  &.md-bold-700{
    @include configFont(14, 18, 700);
  }
  &.lg{
    @include configFont(16, 20, 400);
  }
  &.link{
    color: var(--primary-purple-900);
    text-decoration: underline;
    cursor: pointer;
  }
}


.width-full{
  width: 100%;
}
.flex{
  display: flex;
}
.flex-column{
  flex-direction: column;
}
.flex-row{
  flex-direction: row;
}
.align-items-center{
  align-items: center;
}
.justify-content-between{
  justify-content: space-between;
}
.justify-content-center{
  justify-content: center;
}
.strong{
  font-weight: 700;
}
.link-intern-text{
  cursor: pointer;
  &:active{
    color: inherit;
  }
}
.no-decoration{
  text-decoration: none!important;
}
.redirect-link{
  margin-left: 2px;
  color: var(--primary-purple-900);
  font-weight: 700;
  cursor: pointer;
}
.white-space-nowrap{
  white-space: nowrap;
}
.color-text-default{
  color: var(--neutral-gray-05);
}
.text-purple{
  color: var(--primary-purple-900);
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.btn-icon{
  background-color: transparent;
  border-radius: 50px;
  padding: 2px;
  border: none;
  cursor: pointer;

  &:disabled {
    border: none;
    cursor: default;
    background: var(--neutral-gray-04);
    color: var(--white);
  }

  &:hover{
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  }
}
.border-box{
  box-sizing: border-box;
}
.openedSess{
  overflow: hidden;
  background: #F6F6F6;
}
.color-hr-default{
  color: var(--neutral-gray-04);
}
.ml-auto{
  margin-left: auto;
}
.mg-vertical-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.mg-horizontal-auto{
  margin-left: auto;
  margin-right: auto;
}
.menu-account-btn-icon{
  cursor: pointer;
}
