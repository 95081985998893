.form-container{
  position: relative;

  a.link{
    position: absolute;
    bottom: -2px;
    right: 0;
  }
}
.box-social{

  &__text{
    display: flex;
    gap: 16px;
    hr{
      border-color: #373737;
      flex-grow: 1;
      height: 0px;
      border-style: solid;
      border-block-end: none;
    }
  }


}

.container.signin {
  max-height: initial;
}
