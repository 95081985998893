.container > div.card{
  align-items: flex-start;
}

.container.signup {
  max-height: initial;
}

@media (max-width: 576px) {
  .container.signup > .card {
    height: initial !important;
    margin: auto !important;
  }
}