.loading{
  animation: loading 900ms infinite linear;

  &.white{
    path{
      fill: #fff;
    }
  }

}
@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
