$gray01: var(--neutral-gray-01);
$dark05: var(--neutral-dark-05);

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  white-space: pre;
  text-align: left;
  padding: 6px;
  background-color: #fff;
  color: $dark05;
  border-radius: 4px;
  border: 1px solid $gray01;

  /* Position the tooltip text */
  position: absolute;
  bottom: 125%;
  left: 50%;
  margin-left: -66px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 999;

  @media(max-width: 991px){
    bottom: -63px;
    margin-left: -209px;
  }
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  background: #fff;
  top: 100%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -5px;
  margin-top: -4px;
  transform: rotate(45deg);
  border-width: 1px;
  border-style: solid;
  border-color: transparent $gray01 $gray01 transparent;
  @media(max-width: 991px){
    display: none;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
