.check-force{
  z-index: 999;
  &__bar{
    position: relative;
    display: block;
    background-color: var(--neutral-gray-03);
    height: 5px;
    width: 100%;
    border-radius: 35px;
    overflow: hidden;
    transform: translateY(-8px);

    span{
      position: absolute;
      left: 0;
      top: 0;
      height: 5px;
      background-color: red;
      border-radius: 35px;
    }
  }
  &__tip{
    display: flex;
    justify-content: space-between;
    transform: translateY(-3px);

    svg{
      width: 20px;
      height: 20px;
      margin-left: 6px;
      transform: translateY(-3px);
      cursor: pointer;

      path{
        fill: var(--primary-purple-900)
      }
    }
  }
  &__requires{
    transform: translateY(-4px);
  }

}
.alert-text{
  color: var(--tertiary-brown-900);
}
.success-text{
  color: var(--secondary-green-900);
}

