.social-medias{
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin: 16px 0;

  button{
    background-color: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;
    height: 53px;
    width: 53px;
    overflow: hidden;
    border-radius: 50px;

    svg{
      width: 80px;
      height: 80px;
      transform: translate(-14px, -14px);
      path, circle{
        cursor: pointer;
      }
    }
  }

  &__google.disabled{
    filter: grayscale(100%);
    
    path, circle {
      cursor: wait;
    }
  }

  &__google{
    position: relative;
    height: 53px;
    width: 53px;
    overflow: hidden;
    border-radius: 50px;

    svg{
      width: 80px;
      height: 80px;
      transform: translate(-14px, -14px);
      path, circle{
        cursor: pointer;
      }
    }
  }
}
