.ctv-container {
    height: calc(100vh - 80px);
    padding: 40px 80px;

    @media(max-width: 576px) {
        height: calc(100vh - 48px);
        padding: 24px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .logo-container {
        width: 100%;

        .logo-text {
            svg {
                width: 50px;
                height: 50px;
            }

            span {
                font-size: 24px;
            }
        }
    }

    .ctv-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .error-title {
            margin-bottom: 24px;
        }

        .ctv-title {
            color: var(--neutral-dark-01);
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 43.2px */
            max-width: 335px;;

            @media(max-width: 576px) {
                font-size: 24px;
            }
        }
    
        .ctv-title + p {
            color: var(--neutral-dark-01);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
            max-width: 479px;

            @media(max-width: 576px) {
                max-width: 278px;
            }
        }
    
        svg.loading {
            margin: auto;
            width: 40px;
            height: 40px;
        }
    }

    .button-container {
        align-items: center;

        button  {
            padding: 0px;
            width: 216px;
            max-width: 216px;
        }
    }

    .footer-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center; 
    
        .copyright {
            color: var(--neutral-gray-02);
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px; 
        }
    
        .help-container {
            cursor: pointer;
            display: flex;
            align-items: center;
            column-gap: 24px;
            text-decoration: none;
    
            span {
                color: var(--neutral-dark-01);
                text-align: right;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 19.2px;
                letter-spacing: -1px;
            }
        }

        @media(max-width: 576px) {
            align-items: start;
            flex-direction: column-reverse;

            .copyright {
                margin-top: 32px;
            }
        }
    }
}