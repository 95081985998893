.social-name input{
    text-transform: capitalize;
    &::placeholder{
      text-transform: lowercase;
    }
}
.social-name-inline{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  &__field-name{
    width: 100%;
  }

  &__field-social{
    width: 100%;
    transform: translateY(-9px);
  }

  @media(max-width: 576px){
    grid-template-columns: 1fr;
    gap: 0;
    &__field-social{
      width: 100%;
      transform: translateY(0);

      .mti-32{
        margin-top: 20px!important
      }
    }
  }

}
