.social-medias-icons{
    display: flex;
    justify-content: space-evenly;

    div{
      background-color: transparent;
      border: none;
      padding: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg{
        width: 80px;
        height: 80px;
      }
    }

    &__google{
      position: relative;
      height: 53px;
      width: 53px;
      overflow: hidden;
      border-radius: 50px;

      svg{
        width: 80px;
        height: 80px;
        transform: translate(-14px, -14px);
        path, circle{
          cursor: pointer;
        }
      }
    }
  }
.strong{
  font-weight: 700!important;
}
