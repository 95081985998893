.container{
    max-height: calc(100vh - 72px);
    width: 100%;
    height: 100%;
    padding: 24px;
    box-sizing: border-box;
    overflow: auto;

    &__opened{
        height: 100%;
        padding: 16px;
        padding-bottom: 16px !important;
        flex-direction: column;
    }
}
@media(max-width: 576px){
    .container{
      padding: 0px;
    }
    .container > div.card {
        margin: inherit!important;
        width: 100vw!important;
        height: 100vh!important;
    }
}
